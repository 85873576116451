


.contactSection {
    height: 65vh;
    width: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  
  }
  
  .contact {
    text-align: center;
   display: flex;
   flex-direction: column;

   @media(max-width:600px) {
    top: 120px;
   
   }
   }
  
  .title {
    font-size: 1.6rem;
    color: #56adff;
    font-family: 'Noto Sans', sans-serif;
    font-weight: bold;

    @media(max-width: 600px) {
        font-size: 2.3rem;
    }
  }
  
  .subtitle {
    font-size: 4rem;
    color: rgb(255, 255, 255);
    font-family: 'Belanosima', sans-serif;
    font-weight: bolder;
    padding-top: 10px;

    @media(max-width: 786px) {
        font-size: 2rem;
    }
  }
  
  .contactContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;

    
    
  }
  
  .iconAndEmail {
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 0 1rem;
  }
  
  .email {
    font-size: 20px;
    color: white;

    @media(max-width: 767px) {
      font-size: 10px;
    }
  }
  
  .icon {
    color: #56adff;
    font-size: 25px;

    @media(max-width: 767px) {
      font-size: 20px;
     
    }
  }

  .icon-2 {
    color: #56adff;
    font-size: 25px;

    @media(max-width: 767px) {
      font-size: 20px;
    }
  }

  .location {
    font-size: 20px;
  color: rgb(255, 255, 255);
 
  @media(max-width: 767px) {
    font-size: 10px;
  }
  }
  