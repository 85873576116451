body{
    margin: 0 auto;
    overflow-x: hidden;
   
}

.footer{
height: 10vh;
background-color: #7997fa;
display: flex;
align-items: flex-end;
justify-content: center;


}




.wavesSVGFoot {
    position: relative;
    bottom: 50px;
    width: 100vw;
    height: 30vh;
    margin-bottom:-7px; /*Fix for safari gap*/
    min-height:200px;
    max-height:150px;
   
   @media(max-width: 450px) {
    top: -35px;
    
   }

  }
  
  
  
  .parallax > use {
    animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
  }
  .parallax > use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 16s;
  }
 
  
  @keyframes move-forever {
    0% {
     transform: translate3d(-90px,0,0);
    }
    100% { 
      transform: translate3d(85px,0,0);
    }
  }
  
  
  /* For mobile */
  @media (max-width: 768px) {
    
    
    .waves {
      height:40px;
      min-height:40px;
    }
    .heading {
      height:30vh;
    }
    h1 {
      font-size: 22px;
    }
  }

  .footer-container {
    position: absolute;
    padding-bottom: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    gap: 25px;
  }

  .copyright{
    color: rgb(255, 255, 255);
    font-size: 1.5rem;


    @media(max-width: 676px) {
     font-size: 1rem;
     
    }
   
}

.github{
  height: 35px;
  width: auto;

  @media(max-width: 676px) {
    height: 20px;
  }
}


.linkedin{
  height: 35px;
  width: auto;

  @media(max-width: 676px) {
    height: 20px;
  }
}

.link {
  color: black;
}