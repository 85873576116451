


/* Header.module.css */





body{
    margin: 0 auto;
    overflow-x: hidden;
   
}


.header {
  background: #7997fa;
  height: 62vh;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
  
 
}

.header-hero {
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 position: relative; 
 margin: 0;
 padding: 0;
  height: 100%;
  
}
  
  
  .heading {
   
    z-index: 10;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    
    @media(max-width: 768px) {
      justify-content: center;
    }
  }
  
  .headText {
    font-family: 'Belanosima', sans-serif;
    font-size: 5rem;
    color: rgb(10, 10, 10);
    margin: 0;
    padding: 0;
    width: auto;
    text-align: center;

    @media(max-width: 676px) {
      font-size: 1.7rem;
      
    }
  }
  
  .bodyText {
    font-size: 3rem;
    line-height: 1.4;
    margin-bottom: 8px;
    font-family: 'Belanosima', sans-serif;
    color: rgb(37, 37, 37);
    /* to match the alignment of the heading */
    width: 600px;
    text-align: center;
    font-weight: bold;
    line-height: 2;
    z-index: 10;
    @media(max-width: 768px) {
      font-size: 1.5rem;
      color: white;
    }
}


.wavesSVG {
  position: absolute; /* Position the SVG out of the document flow */
  bottom: 0;          /* Fix the SVG to the bottom of header-hero */
  left: 0;            /* Align the SVG to the left edge of header-hero */
  width: 100%;        /* Make the SVG span the entire width of header-hero */
  margin: 0;
    padding: 0;
    z-index: 8;

    @media(max-width: 786px) {
      bottom: -1px; 
      
    }
}

.waves {
 
 
  width: 100vw;
  height:15vh;
  margin-bottom:-7px; /*Fix for safari gap*/
  min-height:350px;
  max-height:480px;
  margin: 0;
  padding: 0;
 
  @media(max-width: 786px) {
    top: 365px;
    overflow-x: hidden;
  }
  @media(max-width: 400px) {
    top: 230px;
    overflow-x: hidden;
  }
}



.parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}


/* For mobile */
@media (max-width: 768px) {
  .waves {
    min-height: 50px;
  }
  
}