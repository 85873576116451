
.skills{
   height: auto;
  background-color: black;
  display: flex;
  align-items: center;
   justify-content: center;
   flex-direction: column;
   padding: 1rem 2rem;

   @media(max-width: 676px) {
    padding: 10px;
   }

   @media(max-width: 400px) {
    padding: 10px;
   }
}

.skills-container {
display: flex;
width: auto;
gap: 40px;
justify-items: center;
animation: smoothScale 3s ease-in-out infinite;

@media(max-width: 676px) {
  gap: 10px;
}
}



@keyframes smoothScale {
  0%, 100% {
    transform: scale(1); /* Original size */
  }
  50% {
    transform: scale(1.1); /* Slightly larger */
  }
}



.skill-heading {
    font-weight: 900;
    font-size: 1.5rem;
    width: 100%;
    height: 30px;
   text-align: center;
   
    color: rgb(255, 255, 255);
    font-family: 'Noto Sans', sans-serif;

    @media(max-width: 400px) {
      font-size: 15px;
      gap: 10px;
     
     }

    
  }



 

.skill-icon .html {
    color: #5A96E3;
    font-size: 90px;
    @media(max-width: 676px) {
      font-size: 23px;
    }
  
}

.skill-icon .js {
    color: #f4d139;
    font-size: 90px;
    @media(max-width: 676px) {
      font-size: 23px;
    }
  
}

.skill-icon .react {
color: #62c8ff;
font-size: 90px;
font-weight: bold;
@media(max-width: 676px) {
  font-size: 23px;
}

}

.skill-icon .sass {
  color: #f56efc;
  font-size: 90px;
  font-weight: bold;
  @media(max-width: 676px) {
    font-size: 23px;
  }

  }

.skill-icon .node {
    color: #66c13c;
    font-size: 85px;
    @media(max-width: 676px) {
      font-size: 23px;
    }
  
}

.skill-icon .mongodb {
    color: #00DFA2;
    font-size: 90px;
    @media(max-width: 676px) {
      font-size: 23px;
    }

    
}

.skill-icon .postgres {
  color: #3a7ab6;
  font-size: 90px;

  @media(max-width: 676px) {
    font-size: 23px;
  }

  
}

.skill-icon .next {
  color: #909090;
  font-size: 80px;
  @media(max-width: 676px) {
    font-size: 23px;
  }

  }

  .skill-icon .python {
    color: #ffffff;
    font-size: 80px;
    @media(max-width: 676px) {
      font-size: 23px;
    }
  
    }






