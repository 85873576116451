
 
 

.aboutSection {
  height: auto;
    background-color: white;
    width: 100%;
    display: flex;
   align-items: center;
  justify-content: center;

@media(max-width: 767px) {
  align-items: flex-start;
 
}

  }



.card {
    
    background-color: #ffffff;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    border-radius: 8px;
    padding: 20px;
    max-width: 80%;
    height: 500px;
    align-items: center;
    justify-content: center;
    text-align: center;
    @media(max-width: 767px) {
      margin-bottom: 10px;
      height: auto;
      
    }
  }
  
  .cardHeading {
    font-size: 15px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 16px;
    font-family: 'Belanosima', sans-serif;
   display: flex;
   align-items: center;
   justify-content: center;

  }
  
  .about-title{
    font-family: 'Belanosima', sans-serif;
    font-weight: bold;
    font-size: 3.2rem;
   display: flex;
   text-align: center;
   align-items: center;
   justify-content: center;
   
    

   @media(max-width: 786px) {
    font-size: 2rem;
    text-align: center;
  }

  @media(max-width: 480px) {
    font-size: 1.5rem;
  }

    h1 {
      color: black;
    }
  
  }

  .iconSection {
    display: flex;
    justify-content: space-around;
   margin-bottom: 20px;
   margin-top: 20px;
  }
  
  .icon {
    font-size: 24px;
    margin-right: 18px;
   display: flex;
   justify-content: center;
    
  }
  
  .cardParagraph {
    font-size: 20px;
    line-height: 1.5;
    color: #6b6b6b;
    font-family: 'Inter', sans-serif;
    text-align: center;
  
    @media(max-width: 676px) {
      font-size: 0.8rem;
      text-align: center;
    }
  }

 
  .ripple {
color: #00C4FF;
  }

  .btc {
color: #F2BE22;
  }

  .eth{
color: #5A96E3;
  }

  .football{
color: #C38154;
  }

  .hockey{
color: #2B2730;
  }

  .Im_a {
    color: rgb(0, 0, 0);
    font-family: 'Belanosima', sans-serif;
    font-size: 6rem;

    
}

.changed_text {
    color: rgb(55, 125, 229);
}

.changingHeading {
  width: 100%;
}


@media(max-width: 676px) {
  .Im_a {
    font-size: 1.7rem;
    width: 100%;
  }

  .changed_text {
    font-size: 1.7rem;
    width: 100%;
}
}