.navbar {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-family: 'Belanosima', sans-serif;
    font-weight: bold;
    padding: 10px;
    background-color: #000000;
  }
  
  .logo {
  height: 30px;
  width: 30px;

  }
  
  .navLinks {
    list-style: none;
    display: flex;
    gap: 30px;
  
     li {
        margin: 0;
      }

      a {
        color: #ffffff;
        text-decoration: none;
        transition: color 0.3s ease;

        &:hover {
            color: rgb(255, 255, 255);
        }
      }
}