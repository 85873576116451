

.projects {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #7997fa;
  gap: 10px;
 

  @media(max-width: 768px) {
    height: auto; /* Switching from 100vh to auto to let the content determine the height */
    padding: 50px 20px; /* Reduced top/bottom padding and significantly reduced left/right padding */
}

}

.project-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns on desktop */
  gap: 10px; /* space between grid items */
  align-items: center;
  justify-content: center;
  width: 90%; /* Adjust as needed */
  margin: 0 auto;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column; /* single column for mobile */
    align-items: center; /* center items in the column */
  }
}

.project-header {
display: flex;
flex-direction: column;
padding-bottom: 30px;


  

  p {
    color: white;
    background-color: black;
    font-family: 'Noto Sans', sans-serif;
    font-size: 20px;
    padding: 0;
    margin: 0;
    width: 100vw;
   
   text-align: center;
    
   @media(max-width:600px) {
    font-size: 1rem;
  }

  @media(max-width: 450px) {
    font-size: 1rem;
    position: relative;
    top: -51px;
  }

  }

  h5 {
    
    color: rgb(255, 255, 255);
    font-size: 1.7rem;
    font-weight: 700;
    text-align: center;
    padding-top: 120px;
    margin: 0;
    font-family: 'Noto Sans', sans-serif;



    font-family: 'Noto Sans', sans-serif;


    @media(max-width:676px) {
      font-size: 1.2rem;
      width: 100%;
      margin: 0 auto;
      text-align: left;
      
    }

    @media(max-width: 450px) {
      font-size: 1rem;
      padding-top: 10px;
      text-align: center;
    }
  }

}




.project-card {
  
  height: 60%;
  border-radius: 50px;
  background-color: #000;
  padding: 10px;
  box-shadow: 0px 20px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  max-width: 90%; /* Will take up to 90% of its container's width */
   width: 100%; 
  


  @media(max-width:600px) {
    max-width: 95%; /* Allows it to take up the full width if needed */
    width: 300px; /* Overrides the 450px for more flexibility */
    margin: 0 10px; /* Adds a bit of margin on the sides to prevent it from touching the edges */
    height: auto;
}


}

.project-text {
  color: #fff;
  text-align: center;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  font-family: 'Noto Sans', sans-serif;

  @media(max-width: 786px) {
   font-size: .6rem;
   padding: 2px;
   flex-direction: column;
   margin-bottom: 10px;
  }

   

   h1 {

    @media(max-width:786px) {
      font-size: 0.7rem;
      padding: 3px;
      
     }

    
   }
}

.project-card-sub-text {
  padding: 1rem 1rem;

  @media(max-width: 786px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 0;
  }
}




.project-text .tech {
  color: #56adff;
  padding-top: 5px;
  font-family: 'Noto Sans', sans-serif;

  @media(max-width: 786px) {
    font-size: .6rem;
    padding: 5px;
    
   }
}

.demo {
  text-decoration: none;
  color: #6588fb;
  font-family: 'Belanosima', sans-serif;

  @media(max-width: 786px) {
    font-size: .5rem;
    padding: 5px;
    
   }
 
}

.project-img {
  width: 100%;
  height: 400px; /* adjust this to change the visible portion of the image */
  border-radius: 50px;
  overflow: auto; /* allows for scrolling if the image is larger than the specified height */

  img {
    width: 100%;
    object-fit: contain; 
  }
}
  

.block-bank {
  color: rgb(159, 121, 230);
}


.l2c {
  color: rgb(255, 110, 110);
}



