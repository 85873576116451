
.section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 95vh;
    background-color: black;
}

.container {
    width: 100%;
    height: auto;
    padding: 3rem 2rem;
    background-color: black;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
line-height: 0.5;
}



.vin_text{
font-size: 5rem;
color: white;
font-family: 'Belanosima', sans-serif;
}


.Im_a {
    color: white;
    font-family: 'Belanosima', sans-serif;
    font-size: 6rem;
}

.changed_text {
    color: rgb(55, 125, 229);
}



  .head_img {
   border-radius: 48% 52% 33% 67% / 44% 0% 100% 56%;
   height: 200px;
   width: 200px;
   background-color: rgb(89, 171, 252);
  }