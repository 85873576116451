@import url("https://fonts.googleapis.com/css2?family=Belanosima&family=Inter:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@800&display=swap");
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: "Belanosima", sans-serif;
  font-weight: bold;
  padding: 10px;
  background-color: #000000;
}

.logo {
  height: 30px;
  width: 30px;
}

.navLinks {
  list-style: none;
  display: flex;
  gap: 30px;
}
.navLinks li {
  margin: 0;
}
.navLinks a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}
.navLinks a:hover {
  color: rgb(255, 255, 255);
}

/* Header.module.css */
body {
  margin: 0 auto;
  overflow-x: hidden;
}

.header {
  background: #7997fa;
  height: 62vh;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
}

.header-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
}

.heading {
  z-index: 10;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 768px) {
  .heading {
    justify-content: center;
  }
}

.headText {
  font-family: "Belanosima", sans-serif;
  font-size: 5rem;
  color: rgb(10, 10, 10);
  margin: 0;
  padding: 0;
  width: auto;
  text-align: center;
}
@media (max-width: 676px) {
  .headText {
    font-size: 1.7rem;
  }
}

.bodyText {
  font-size: 3rem;
  line-height: 1.4;
  margin-bottom: 8px;
  font-family: "Belanosima", sans-serif;
  color: rgb(37, 37, 37);
  /* to match the alignment of the heading */
  width: 600px;
  text-align: center;
  font-weight: bold;
  line-height: 2;
  z-index: 10;
}
@media (max-width: 768px) {
  .bodyText {
    font-size: 1.5rem;
    color: white;
  }
}

.wavesSVG {
  position: absolute; /* Position the SVG out of the document flow */
  bottom: 0; /* Fix the SVG to the bottom of header-hero */
  left: 0; /* Align the SVG to the left edge of header-hero */
  width: 100%; /* Make the SVG span the entire width of header-hero */
  margin: 0;
  padding: 0;
  z-index: 8;
}
@media (max-width: 786px) {
  .wavesSVG {
    bottom: -1px;
  }
}

.waves {
  width: 100vw;
  height: 15vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 350px;
  max-height: 480px;
  margin: 0;
  padding: 0;
}
@media (max-width: 786px) {
  .waves {
    top: 365px;
    overflow-x: hidden;
  }
}
@media (max-width: 400px) {
  .waves {
    top: 230px;
    overflow-x: hidden;
  }
}

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/* For mobile */
@media (max-width: 768px) {
  .waves {
    min-height: 50px;
  }
}
.section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 95vh;
  background-color: black;
}

.container {
  width: 100%;
  height: auto;
  padding: 3rem 2rem;
  background-color: black;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  line-height: 0.5;
}

.vin_text {
  font-size: 5rem;
  color: white;
  font-family: "Belanosima", sans-serif;
}

.Im_a {
  color: white;
  font-family: "Belanosima", sans-serif;
  font-size: 6rem;
}

.changed_text {
  color: rgb(55, 125, 229);
}

.head_img {
  border-radius: 48% 52% 33% 67%/44% 0% 100% 56%;
  height: 200px;
  width: 200px;
  background-color: rgb(89, 171, 252);
}

.aboutSection {
  height: auto;
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .aboutSection {
    align-items: flex-start;
  }
}

.card {
  background-color: #ffffff;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  padding: 20px;
  max-width: 80%;
  height: 500px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media (max-width: 767px) {
  .card {
    margin-bottom: 10px;
    height: auto;
  }
}

.cardHeading {
  font-size: 15px;
  font-weight: bold;
  color: #000000;
  margin-bottom: 16px;
  font-family: "Belanosima", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-title {
  font-family: "Belanosima", sans-serif;
  font-weight: bold;
  font-size: 3.2rem;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
@media (max-width: 786px) {
  .about-title {
    font-size: 2rem;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .about-title {
    font-size: 1.5rem;
  }
}
.about-title h1 {
  color: black;
}

.iconSection {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  margin-top: 20px;
}

.icon {
  font-size: 24px;
  margin-right: 18px;
  display: flex;
  justify-content: center;
}

.cardParagraph {
  font-size: 20px;
  line-height: 1.5;
  color: #6b6b6b;
  font-family: "Inter", sans-serif;
  text-align: center;
}
@media (max-width: 676px) {
  .cardParagraph {
    font-size: 0.8rem;
    text-align: center;
  }
}

.ripple {
  color: #00C4FF;
}

.btc {
  color: #F2BE22;
}

.eth {
  color: #5A96E3;
}

.football {
  color: #C38154;
}

.hockey {
  color: #2B2730;
}

.Im_a {
  color: rgb(0, 0, 0);
  font-family: "Belanosima", sans-serif;
  font-size: 6rem;
}

.changed_text {
  color: rgb(55, 125, 229);
}

.changingHeading {
  width: 100%;
}

@media (max-width: 676px) {
  .Im_a {
    font-size: 1.7rem;
    width: 100%;
  }
  .changed_text {
    font-size: 1.7rem;
    width: 100%;
  }
}
.skills {
  height: auto;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem 2rem;
}
@media (max-width: 676px) {
  .skills {
    padding: 10px;
  }
}
@media (max-width: 400px) {
  .skills {
    padding: 10px;
  }
}

.skills-container {
  display: flex;
  width: auto;
  gap: 40px;
  justify-items: center;
  animation: smoothScale 3s ease-in-out infinite;
}
@media (max-width: 676px) {
  .skills-container {
    gap: 10px;
  }
}

@keyframes smoothScale {
  0%, 100% {
    transform: scale(1); /* Original size */
  }
  50% {
    transform: scale(1.1); /* Slightly larger */
  }
}
.skill-heading {
  font-weight: 900;
  font-size: 1.5rem;
  width: 100%;
  height: 30px;
  text-align: center;
  color: rgb(255, 255, 255);
  font-family: "Noto Sans", sans-serif;
}
@media (max-width: 400px) {
  .skill-heading {
    font-size: 15px;
    gap: 10px;
  }
}

.skill-icon .html {
  color: #5A96E3;
  font-size: 90px;
}
@media (max-width: 676px) {
  .skill-icon .html {
    font-size: 23px;
  }
}

.skill-icon .js {
  color: #f4d139;
  font-size: 90px;
}
@media (max-width: 676px) {
  .skill-icon .js {
    font-size: 23px;
  }
}

.skill-icon .react {
  color: #62c8ff;
  font-size: 90px;
  font-weight: bold;
}
@media (max-width: 676px) {
  .skill-icon .react {
    font-size: 23px;
  }
}

.skill-icon .sass {
  color: #f56efc;
  font-size: 90px;
  font-weight: bold;
}
@media (max-width: 676px) {
  .skill-icon .sass {
    font-size: 23px;
  }
}

.skill-icon .node {
  color: #66c13c;
  font-size: 85px;
}
@media (max-width: 676px) {
  .skill-icon .node {
    font-size: 23px;
  }
}

.skill-icon .mongodb {
  color: #00DFA2;
  font-size: 90px;
}
@media (max-width: 676px) {
  .skill-icon .mongodb {
    font-size: 23px;
  }
}

.skill-icon .postgres {
  color: #3a7ab6;
  font-size: 90px;
}
@media (max-width: 676px) {
  .skill-icon .postgres {
    font-size: 23px;
  }
}

.skill-icon .next {
  color: #909090;
  font-size: 80px;
}
@media (max-width: 676px) {
  .skill-icon .next {
    font-size: 23px;
  }
}

.skill-icon .python {
  color: #ffffff;
  font-size: 80px;
}
@media (max-width: 676px) {
  .skill-icon .python {
    font-size: 23px;
  }
}

.projects {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #7997fa;
  gap: 10px;
}
@media (max-width: 768px) {
  .projects {
    height: auto; /* Switching from 100vh to auto to let the content determine the height */
    padding: 50px 20px; /* Reduced top/bottom padding and significantly reduced left/right padding */
  }
}

.project-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 columns on desktop */
  gap: 10px; /* space between grid items */
  align-items: center;
  justify-content: center;
  width: 90%; /* Adjust as needed */
  margin: 0 auto;
}
@media (max-width: 768px) {
  .project-content {
    display: flex;
    flex-direction: column; /* single column for mobile */
    align-items: center; /* center items in the column */
  }
}

.project-header {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}
.project-header p {
  color: white;
  background-color: black;
  font-family: "Noto Sans", sans-serif;
  font-size: 20px;
  padding: 0;
  margin: 0;
  width: 100vw;
  text-align: center;
}
@media (max-width: 600px) {
  .project-header p {
    font-size: 1rem;
  }
}
@media (max-width: 450px) {
  .project-header p {
    font-size: 1rem;
    position: relative;
    top: -51px;
  }
}
.project-header h5 {
  color: rgb(255, 255, 255);
  font-size: 1.7rem;
  font-weight: 700;
  text-align: center;
  padding-top: 120px;
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  font-family: "Noto Sans", sans-serif;
}
@media (max-width: 676px) {
  .project-header h5 {
    font-size: 1.2rem;
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }
}
@media (max-width: 450px) {
  .project-header h5 {
    font-size: 1rem;
    padding-top: 10px;
    text-align: center;
  }
}

.project-card {
  height: 60%;
  border-radius: 50px;
  background-color: #000;
  padding: 10px;
  box-shadow: 0px 20px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  max-width: 90%; /* Will take up to 90% of its container's width */
  width: 100%;
}
@media (max-width: 600px) {
  .project-card {
    max-width: 95%; /* Allows it to take up the full width if needed */
    width: 300px; /* Overrides the 450px for more flexibility */
    margin: 0 10px; /* Adds a bit of margin on the sides to prevent it from touching the edges */
    height: auto;
  }
}

.project-text {
  color: #fff;
  text-align: center;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  font-family: "Noto Sans", sans-serif;
}
@media (max-width: 786px) {
  .project-text {
    font-size: 0.6rem;
    padding: 2px;
    flex-direction: column;
    margin-bottom: 10px;
  }
}
@media (max-width: 786px) {
  .project-text h1 {
    font-size: 0.7rem;
    padding: 3px;
  }
}

.project-card-sub-text {
  padding: 1rem 1rem;
}
@media (max-width: 786px) {
  .project-card-sub-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 0;
  }
}

.project-text .tech {
  color: #56adff;
  padding-top: 5px;
  font-family: "Noto Sans", sans-serif;
}
@media (max-width: 786px) {
  .project-text .tech {
    font-size: 0.6rem;
    padding: 5px;
  }
}

.demo {
  text-decoration: none;
  color: #6588fb;
  font-family: "Belanosima", sans-serif;
}
@media (max-width: 786px) {
  .demo {
    font-size: 0.5rem;
    padding: 5px;
  }
}

.project-img {
  width: 100%;
  height: 400px; /* adjust this to change the visible portion of the image */
  border-radius: 50px;
  overflow: auto; /* allows for scrolling if the image is larger than the specified height */
}
.project-img img {
  width: 100%;
  object-fit: contain;
}

.block-bank {
  color: rgb(159, 121, 230);
}

.l2c {
  color: rgb(255, 110, 110);
}

.contactSection {
  height: 65vh;
  width: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.contact {
  text-align: center;
  display: flex;
  flex-direction: column;
}
@media (max-width: 600px) {
  .contact {
    top: 120px;
  }
}

.title {
  font-size: 1.6rem;
  color: #56adff;
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
}
@media (max-width: 600px) {
  .title {
    font-size: 2.3rem;
  }
}

.subtitle {
  font-size: 4rem;
  color: rgb(255, 255, 255);
  font-family: "Belanosima", sans-serif;
  font-weight: bolder;
  padding-top: 10px;
}
@media (max-width: 786px) {
  .subtitle {
    font-size: 2rem;
  }
}

.contactContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.iconAndEmail {
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 0 1rem;
}

.email {
  font-size: 20px;
  color: white;
}
@media (max-width: 767px) {
  .email {
    font-size: 10px;
  }
}

.icon {
  color: #56adff;
  font-size: 25px;
}
@media (max-width: 767px) {
  .icon {
    font-size: 20px;
  }
}

.icon-2 {
  color: #56adff;
  font-size: 25px;
}
@media (max-width: 767px) {
  .icon-2 {
    font-size: 20px;
  }
}

.location {
  font-size: 20px;
  color: rgb(255, 255, 255);
}
@media (max-width: 767px) {
  .location {
    font-size: 10px;
  }
}

body {
  margin: 0 auto;
  overflow-x: hidden;
}

.footer {
  height: 10vh;
  background-color: #7997fa;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.wavesSVGFoot {
  position: relative;
  bottom: 50px;
  width: 100vw;
  height: 30vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 200px;
  max-height: 150px;
}
@media (max-width: 450px) {
  .wavesSVGFoot {
    top: -35px;
  }
}

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 16s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
/* For mobile */
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
  .heading {
    height: 30vh;
  }
  h1 {
    font-size: 22px;
  }
}
.footer-container {
  position: absolute;
  padding-bottom: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  gap: 25px;
}

.copyright {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
}
@media (max-width: 676px) {
  .copyright {
    font-size: 1rem;
  }
}

.github {
  height: 35px;
  width: auto;
}
@media (max-width: 676px) {
  .github {
    height: 20px;
  }
}

.linkedin {
  height: 35px;
  width: auto;
}
@media (max-width: 676px) {
  .linkedin {
    height: 20px;
  }
}

.link {
  color: black;
}

